import React from 'react'
import * as S from './styles'

export enum BackgroundColor {
  Black = "black",
  White = "white"
}

const PageSlider = ({ show, background = "white", children }: {show: boolean; background?: "black" | "white"; children: React.ReactNode}) => {
  return (
    <S.PageSlider show={show} background={background}>
      {children}
    </S.PageSlider>
  )
}

export default PageSlider
