import { IWbAppInfo } from "@interco/inter-webview-bridge"
import { InterBridge } from "./_useInterBridgeInstance"
import { enableDarkMode } from "@interco/inter-ui"

export const useDarkMode = () => {
  const setDarkMode = (iWb: InterBridge | undefined) => {
    const appInfo = iWb?.interWbSession.getAppInfo()
    if (appInfo) {
      appInfo.then((appInfo: IWbAppInfo) => {
        if (appInfo?.isDarkMode) {
          enableDarkMode()
        }
      })
    }
  }

  return {
    setDarkMode,
  }
}
