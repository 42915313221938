/* eslint-disable no-unused-expressions */
import React from 'react'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { primary } from 'src/styles/newColors'
import styled from 'styled-components'
import { Button } from '@interco/inter-ui/components/Button'
import Helmet from 'react-helmet'

type TesouroMePoupeAppHeaderProps = {
  showHelpBtn?: boolean;
  onHelpClick?: () => void;
  onBackClick?: () => void;
}

const Header = styled.header`
  // appbar title
  & span {
    font-family: 'Inter', sans-serif !important;
  }
  svg {
    width: 24px;
    height: 24px;
  }

  button {
    padding: 0.25rem;
  }

  .help-btn-placeholder {
    height: 32px;
    width: 32px;
  }
`

const TesouroMePoupeAppHeader: React.FC<TesouroMePoupeAppHeaderProps> = ({ showHelpBtn, onHelpClick, onBackClick }: TesouroMePoupeAppHeaderProps) => {
  const handleHelpClick = async () => {
    onHelpClick?.()
  }

  const handleBackClick = async () => {
    onBackClick?.()
  }

  return (
    <Header>
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
        <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Inter&display=swap' />
        <link href='https://cdn.bancointer.com.br/inter-assets/latest/css/citrina.css' rel='stylesheet' />
      </Helmet>
      <AppBar
        leftIcon={
          <Button variant='link' onClick={handleBackClick}>
            <IconsSwitch color={primary[500]} size='XL' icon='action-navigation/ic_arrow_left' lib='interco' />
          </Button>
      }
        rightIcon={
          <div className='help-btn-placeholder'>
            {
              showHelpBtn && (
                <Button variant='link' onClick={handleHelpClick}>
                  <IconsSwitch color={primary[500]} size='XL' icon='status/ic_help_circle' lib='interco' />
                </Button>
                )
            }
          </div>
        }
      >
        Construa seu tesouro
      </AppBar>
    </Header>
  )
}

export default TesouroMePoupeAppHeader
