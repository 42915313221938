import styled from "styled-components"
import { BackgroundColor } from "./_PageSlider"

type PageSliderStyles = {
  show: boolean;
  background: string;
}

export const PageSlider = styled.div<PageSliderStyles>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props: PageSliderStyles) => props.background === BackgroundColor.White ? 'var(--neutral-theme)' : '#000' };
  transition: all .6s;
  overflow-x: hidden;
  transform: ${(props: PageSliderStyles) => !props.show ? 'translate(100%,0)' : 'translate(0,0)' };
`
