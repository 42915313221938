import React, { useEffect } from "react"
import Picture from "src/components/Picture"
import * as S from "./styles"
import CardWithButton from "./components/CardWithButton/_CardWithButton"

import { Button } from "@interco/inter-ui/components/Button"
import { BottomSheet } from "@interco/inter-ui/components/BottomSheet"
import IconsSwitch from "src/components/IconsSwitch/_index"
import { primary } from "src/styles/newColors"
import { initTheme, Theme } from "@interco/inter-ui"
import InvestmentsBottomSheet from "./components/InvestmentsDisplay/_InvestmentsDisplay"
import { useInvestmentData } from "./hooks/_useInvestmentData"
import TesouroMePoupeAppHeader from "./components/TesouroMePoupeAppHeader/_TesouroMePoupeAppHeader"
import { useInterBridgeInstance } from "./hooks/_useInterBridgeInstance"
import PageSlider from "./components/PageSlider/_PageSlider"
import TesouroMePoupeAppFAQ from "./FAQ/_TesouroMePoupeAppFAQ"
import YoutubeVideo from "src/components/YoutubeVideo"
import { usePageSliderReducer } from "./hooks/_usePageSliderReducer"
import {
  TEXT_CONTENT,
} from "./_constants"
import { MenuOption } from "./_types"

import { tagBackClick, tagCardClick, tagCtaClick, tagHelpClick, tagPageLoad, tagVideoClick } from "./tagHelpers/_index"
import { useDarkMode } from "./hooks/_useDarkMode"

const TesouroMePoupe: React.FC = () => {
  const [ showBottomSheet, setShowBottomSheet ] = React.useState(false)

  const {
    data: selectedInvestment,
    setInvestment,
  }: ReturnType<typeof useInvestmentData> = useInvestmentData()
  const iWb = useInterBridgeInstance()
  const {
    pageSliderState,
    openFaq,
    openVideo,
    closePageSlider,
  }: ReturnType<typeof usePageSliderReducer> = usePageSliderReducer()
  const { setDarkMode }: ReturnType<typeof useDarkMode> = useDarkMode()

  useEffect(() => {
    initTheme(Theme.PF)
    tagPageLoad(iWb)
    setDarkMode(iWb)
  })

  const handleCardClick = async (menuOption: MenuOption) => {
    setInvestment(menuOption.fileToLoad)
    setShowBottomSheet(true)
    await tagCardClick(iWb, menuOption.title)
  }

  const handleVideoClick = async () => {
    openVideo()
    await tagVideoClick(iWb)
  }

  const handleCtaClick = async () => {
    await tagCtaClick(iWb)
    await iWb?.interWbNavigate.openDeepLink("bancointer://investimentos/investir/tesouroDireto?campaign=CampanhaMePoupeTD&filtro=destaque")
  }

  const handleHelpClick = async () => {
    openFaq()
    await tagHelpClick(iWb)
  }

  const handleBackClick = async () => {
    closePageSlider()
    await tagBackClick(iWb)
    if (!pageSliderState.isVisibile) {
      await iWb?.interWbNavigate.requestGoBack()
    }
  }

  return (
    <>
      <S.MobileFrame>
        <TesouroMePoupeAppHeader
          showHelpBtn={!pageSliderState.isVisibile}
          onHelpClick={handleHelpClick}
          onBackClick={handleBackClick}
        />
        <S.TesouroMePoupeAppSection
          id='webview-construa-seu-tesouro'
          isFaqVisible={pageSliderState.forFaq}
          isVideoVisible={pageSliderState.forVideo}
        >
          <PageSlider show={pageSliderState.forFaq} background='white'>
            <TesouroMePoupeAppFAQ />
          </PageSlider>
          <PageSlider
            show={pageSliderState.forVideo}
            background='black'
          >
            <div className='video-embeded-frame'>
              <YoutubeVideo
                src='https://www.youtube.com/embed/lMGH3l_232g?si=YNJwP_ieIiBfIn94'
                width='100%'
                className='my-auto'
                height='210px'
              />
            </div>
          </PageSlider>
          {!pageSliderState.isVisibile && (
            <>
              <div className='video-block' onClick={handleVideoClick}>
                <Picture
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/banner3-nathalia/image.webp'
                  minHeight={{ sm: '163px' }}
                  minWidth={{ sm: '309px' }}
                  height='auto'
                  width='100%'
                  imgClassName='mx-auto'
                />
              </div>
              <h1 className='title'>{TEXT_CONTENT.heading}</h1>

              <S.CardsGrid>
                {TEXT_CONTENT.menuOptions.map(
                (menuOption: MenuOption, index: number) => (
                  <li key={index}>
                    <CardWithButton
                      icon={menuOption.icon}
                      title={menuOption.title}
                      onButtonClick={() => {
                        handleCardClick(menuOption)
                      }}
                    />
                  </li>
                ),
              )}
              </S.CardsGrid>
              <div
                className='button-block'
              >
                <Button
                  fullWidth
                  variant='secondary'
                  icon={
                    <IconsSwitch
                      lib='interco'
                      icon='action-navigation/ic_chevron_right'
                      size='LG'
                      color={primary[500]}
                    />
                }
                  onClick={handleCtaClick}
                >
                  {TEXT_CONTENT.cta}
                </Button>
              </div>

              <S.BannerBox>
                <Picture
                  minHeight={{ sm: "109px" }}
                  width='100%'
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/webview-tesouro-direto-1/image.webp'
                  alt=''
                />
                <div className='banner-box__text'>
                  <h2
                    className='banner-box__title'
                    dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.bannerTitle }}
                  />
                  <p className='banner-box__body'>{TEXT_CONTENT.bannerBody}</p>
                </div>
              </S.BannerBox>
              {showBottomSheet && selectedInvestment && (
                <BottomSheet onClose={() => setShowBottomSheet(false)}>
                  <InvestmentsBottomSheet investment={selectedInvestment} />
                </BottomSheet>
            )}
            </>
        )}
        </S.TesouroMePoupeAppSection>
      </S.MobileFrame>
    </>
  )
}

export default TesouroMePoupe
